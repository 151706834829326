import Vue from 'vue/dist/vue.esm.js';
import axios from 'axios';
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter);

const token = document
  .getElementsByName('csrf-token')[0]
  .getAttribute('content');

document.addEventListener('DOMContentLoaded', () => {
  let vm = new Vue({
    el: '#publicHeader',
    data: {
      menuVisible: false,
      notificationVisible: false,
      menuType: 'user',
      notifications: [],
      unreadCount: 0,
    },
    methods: {
      showHeaderMenu() {
        this.menuVisible = true;
      },
      hideHeaderMenu() {
        this.menuVisible = false;
      },
      toogleHeaderMenu() {
        this.menuVisible = !this.menuVisible;
      },
      setMenuType(menuType) {
        this.menuType = menuType;
      },
    },
  });
});
